import styled from '@emotion/styled';
import { motion, useAnimation } from 'framer-motion';
import { signIn, useSession } from 'next-auth/react';
import { useSearchParams } from 'next/navigation';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import * as yup from 'yup';
import Button from '@/components/Button/Button';
import FormInput from '@/components/Forms/FormInput';
import fetcher from '@/lib/fetcher';
import { getTimezoneKey } from '@/util/date-fns-helpers';
import dayjs from '@/util/dayjs';
import Spinner from '../Spinner';
const LoginCard = styled.div`
  width: 90%;
  max-width: 528px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px 51px;
  gap: 25px;
  border-radius: 30px;
  background: var(--White, #fff);

  @media (max-width: 480px) {
    width: 100%;
    padding: 33px;
    background: none;
  }

  @media (min-width: 768px) {
    /* Styles for larger screens */
    padding: 40px 51px;
  }
`;
const TitlePrompt = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 14px;

  h1 {
    color: #3e3e3e;
    text-align: center;
    font-family: 'GT Eesti Text';
    font-style: normal;
    font-weight: 400;
    line-height: 110%; /* 33px */
    letter-spacing: -0.36px;
    font-size: 24px;

    @media (min-width: 768px) {
      font-size: 30px;
      letter-spacing: -0.45px;
    }
  }

  p {
    color: #5d7179;
    text-align: center;
    font-family: 'GT Eesti Text';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 145%; /* 23.2px */
  }
`;
const TermsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #777;
  text-align: center;
  font-family: 'GT Eesti Text';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  button {
    background: none;
    border: none;
    padding: none;
    color: var(--Primary, #3a55de);
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }
  }
`;
const phoneSchema = yup.string().matches(/^1?[2-9]\d{2}[2-9]\d{2}\d{4}$/, 'Please enter a valid US phone number (10 digits, no dashes or spaces).').required('Phone is required.');
const otpSchema = yup.string().matches(/^\d{6}$/, 'Please enter a valid 6-digit code.').required('Code is required.');
const shakeAnimation = {
  shake: {
    x: [-10, 10, -10, 10, 0],
    transition: {
      duration: 0.4,
      ease: 'easeInOut'
    }
  }
};
export default function PatientLogin() {
  const router = useRouter();
  const session = useSession();
  const searchParams = useSearchParams();
  const referredBy = searchParams.get('referredBy');
  const controls = useAnimation();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [verificationSid, setVerificationSid] = useState<string>('');
  const [otpCode, setOtpCode] = useState<string>('');
  const [otpGenerated, setOtpGenerated] = useState<boolean>(false);
  const reset = () => {
    setPhoneNumber('');
    setError('');
    setVerificationSid('');
    setOtpCode('');
    setOtpGenerated(false);
  };
  const updateNumber = (val: string, cb: (val: string) => void) => {
    if (!/^\d*$/.test(val)) {
      controls.start('shake');
      setError('Please only enter digits.');
    } else {
      setError('');
      cb(val);
    }
  };
  const generateOtpCode = async () => {
    setIsLoading(true);
    try {
      await phoneSchema.validate(phoneNumber);
      setError('');

      // just to allow people to put a 1 in sometimes because the web is not consistent about it
      const pN = phoneNumber.length === 11 && phoneNumber[0] === '1' ? phoneNumber.slice(1) : phoneNumber;
      const otpResponse = await fetcher('/api/auth/generate-otp', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          phoneNumber: pN
        })
      });
      setVerificationSid(otpResponse.sid);
      setOtpGenerated(true);
    } catch (err) {
      controls.start('shake');
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };
  const signInUser = async () => {
    setIsLoading(true);
    try {
      await otpSchema.validate(otpCode);
      setError('');
      const userTimezone = dayjs.tz.guess();
      const signInResponse = await signIn('credentials', {
        timezone: getTimezoneKey(userTimezone),
        phoneNumber,
        verificationSid,
        code: otpCode,
        redirect: false
      });
      if (signInResponse?.error) {
        console.log('error', signInResponse);
        setError('Sign in failed.');
      }
    } catch (err) {
      controls.start('shake');
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    if (session?.data?.user?.role === 'patient') {
      if (referredBy) {
        fetch('/api/patient/update', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            referredBy,
            id: session.data?.user?.id
          })
        });
      }
      router.push('/patient/dashboard');
    }
  }, [session]);
  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        if (otpGenerated) {
          if (!error.length) {
            signInUser();
          }
        } else {
          if (!error.length) {
            generateOtpCode();
          }
        }
      }
    };
    window.addEventListener('keydown', handleKeyPress);
    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [otpGenerated, error, otpCode, phoneNumber]);
  if (session?.status === 'loading') {
    return <Spinner />;
  }
  return <>
      {otpGenerated ? <>
          <LoginCard>
            <div style={{
          width: '200px',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}>
              <img src="/mh-logo.svg" alt="logo" style={{
            width: 'auto',
            height: '50px'
          }} />
            </div>

            <TitlePrompt>
              <h1>Enter your code</h1>
              <p>We just texted you a 6-digit verification code, enter it below:</p>
            </TitlePrompt>

            <motion.div animate={controls} variants={shakeAnimation} style={{
          width: '100%'
        }}>
              <FormInput id="code-verification-login-input" name="code" label="Enter Code" currentValue={otpCode} updateFn={val => updateNumber(val, setOtpCode)} error={{
            type: 'error',
            message: error
          }} />
            </motion.div>

            <Button variant="primary" style={{
          width: '100%'
        }} onClick={() => signInUser()} disabled={!!error.length}>
              Next
            </Button>

            <TermsContainer>
              Didn't get a code? <button onClick={reset}>Enter your number again</button>
            </TermsContainer>
          </LoginCard>
        </> : <>
          <LoginCard>
            <div style={{
          width: '200px',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}>
              <img src="/mh-logo.svg" alt="logo" style={{
            width: 'auto',
            height: '50px'
          }} />
            </div>

            <TitlePrompt>
              <h1>Welcome to Modern Halo</h1>
              <p>Enter your phone number to get started:</p>
            </TitlePrompt>

            <motion.div animate={controls} variants={shakeAnimation} style={{
          width: '100%'
        }}>
              <FormInput id="phone-number-login-input" name="phoneNumber" label="Phone Number" currentValue={phoneNumber} updateFn={val => updateNumber(val, setPhoneNumber)} error={{
            type: 'error',
            message: error
          }} />
            </motion.div>

            <Button variant="primary" style={{
          width: '100%'
        }} onClick={() => generateOtpCode()} disabled={!!error.length}>
              Get Started
            </Button>
          </LoginCard>
        </>}
    </>;
}