import styled from '@emotion/styled';
import { ChangeEvent, FocusEvent, forwardRef, RefObject, useEffect, useState } from 'react';
import { FieldError, FieldValues, UseFormRegister } from 'react-hook-form';
const Container = styled.div`
  width: 100%;
`;
const ErrorText = styled.p`
  color: var(--Error, #f45555);
  font-family: 'GT Eesti Text';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 145%; /* 17.4px */
  margin-top: 6px;
  margin-left: 4px;
`;
const FormGroup = styled.div<{
  hasError?: boolean;
}>`
  position: relative;
  width: 100%;

  &.focused .form-label,
  &.filled .form-label {
    transform: translateY(-130%) translateX(-10%) scale(0.8);
    padding: 0px 4px;
  }

  &.disabled .form-label {
    transform: translateY(-170%) translateX(-10%) scale(0.8);
  }
`;
const FormLabel = styled.label`
  position: absolute;
  left: 10px;
  top: 55%;
  transform: translateY(-55%);
  background: none;
  z-index: 10;
  transition: all 150ms ease-out;
  cursor: text;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(100% - 28px);
  color: var(--Color, #99a7ae);
  font-family: 'GT Eesti Text';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 145%; /* 23.2px */
`;
const FormInput = styled.input`
  position: relative;
  padding: 15px 14px;
  width: 100%;
  outline: 0;
  border-radius: 10px;
  border: 1px solid rgba(0, 35, 52, 0.2);
  background: var(--White, #fff);
  box-shadow: 0 1px 0 0 #e5e5e5;
  transition: box-shadow 150ms ease-out, border-color 150ms ease-out;

  color: var(--Body---Brand, #3e3e3e);
  font-family: 'GT Eesti Text';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 145%; /* 23.2px */

  &:-webkit-autofill {
    animation-name: autofill;
    animation-fill-mode: both;
  }

  &:-webkit-autofill:disabled {
    border: none;
    animation-name: none;
    animation-fill-mode: none;
  }

  @keyframes autofill {
    0%,
    100% {
      color: #666;
      background: var(--White, #fff);
    }
  }

  &.error {
    border-color: #f45555;
  }

  &:disabled {
    border-color: transparent;
    box-shadow: none;
  }
`;

// Add some styling for the units
const UnitsSpan = styled.span`
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #888;
`;

// Type definitions for component props
// Note: these props are used in NumericalInput as well, so any changes here will affect that component as well
export interface InputWithAnimatedPlaceholderProps<T extends FieldValues> {
  id: string;
  label: string;
  currentValue: string;
  type?: string;
  step?: string;
  updateFn: (value: string) => void;
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
  error?: FieldError | {
    message: string;
  };
  dirty?: boolean;
  register?: UseFormRegister<T>;
  name: string;
  maxLength?: number;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  units?: string;
}

/**
 * InputWithAnimatedPlaceholder Component
 *
 * A custom input component with an animated placeholder.
 *
 * @param {string} id - The id of the input element.
 * @param {string} label - The label for the input element.
 * @param {string} currentValue - The current value of the input.
 * @param {(value: string) => void} updateFn - Function to update the input value.
 * @param {(event: FocusEvent<HTMLInputElement>) => void} [onBlur] - Optional blur event handler.
 * @param {FieldError | { message: string }} error - Optional error message(s)
 * @param {boolean} dirty - Indicates whether or not unsaved changes have been made to this field
 * @param {boolean} disabled - Indicates whether or not the input is disabled
 */
const InputWithAnimatedPlaceholder = forwardRef<HTMLInputElement, InputWithAnimatedPlaceholderProps<FieldValues>>(({
  id,
  label,
  currentValue,
  updateFn,
  onBlur,
  type = 'text',
  step,
  error,
  dirty = false,
  register,
  name,
  onKeyDown,
  maxLength,
  disabled = false,
  units = ''
}, ref) => {
  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(!!currentValue);

  /**
   * Handles the focus event on the input.
   */
  const handleFocus = () => {
    setIsFocused(true);
  };

  /**
   * Handles the blur event on the input.
   *
   * @param {FocusEvent<HTMLInputElement>} event - The blur event.
   */
  const handleBlur = (event: FocusEvent<HTMLInputElement>) => {
    setIsFocused(false);
    setIsFilled(event.target.value !== '');
    if (onBlur) onBlur(event);
  };

  /**
   * Handles the autofill animation event.
   *
   * @param {AnimationEvent} event - The animation event.
   */
  const handleAutoFillWrapper = (event: Event) => {
    if (event instanceof AnimationEvent && event.animationName === 'autofill') {
      setIsFilled(true);
    }
  };
  useEffect(() => {
    const input = document.getElementById(id) as HTMLInputElement;
    input.addEventListener('animationstart', handleAutoFillWrapper);
    return () => {
      input.removeEventListener('animationstart', handleAutoFillWrapper);
    };
  }, [id]);
  useEffect(() => {
    setIsFilled(!!currentValue);
  }, [currentValue]);
  const hasError = error && !isFocused && error?.message?.length > 0;
  return <Container>
        <FormGroup className={`${isFocused ? 'focused' : ''} ${isFilled ? 'filled' : ''} ${disabled ? 'disabled' : ''}`} hasError={hasError}>
          <FormLabel className="form-label" htmlFor={id}>
            {label}
          </FormLabel>
          <FormInput id={id} disabled={disabled} className={hasError || dirty ? 'error' : 'form-input'} type={type} step={step} onFocus={handleFocus} onBlur={handleBlur} onChange={(e: ChangeEvent<HTMLInputElement>) => updateFn(e.target.value)} value={currentValue} ref={ref as RefObject<HTMLInputElement>} onKeyDown={onKeyDown} maxLength={maxLength} {...register && register(name)} />
          {units?.length > 0 && <UnitsSpan>{units}</UnitsSpan>}
        </FormGroup>
        {hasError && <ErrorText>{error.message}</ErrorText>}
      </Container>;
});
InputWithAnimatedPlaceholder.displayName = 'InputWithAnimatedPlaceholder';
export default InputWithAnimatedPlaceholder;